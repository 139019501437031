import { CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Form, Modal, Popconfirm } from 'antd';
import clsx from 'clsx';
import { filter, findIndex, includes, map } from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  PROJECT_ROLES_LABEL,
  USER_LIST_ACTIONS,
} from '../../common/constants';
import { titleCase } from '../../common/utils';
import CommonCard from '../../components/CommonCard';
import CommonDropdown from '../../components/CommonDropdown';
import CommonTable from '../../components/CommonTable';
import EllipsisText from '../../components/EllipsisText';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import ReplaceUserModal from './ReplaceUserModal';
import { DEACTIVATE_USER_APPROVER, REPLICATE_USER } from './graphql/Mutations';
import { GET_USERS_DROPDOWN_LIST, USER_PROJECT_LIST } from './graphql/Queries';

const ReplaceReplicateModal = ({
  showModal,
  setShowModal,
  userRecord,
  refetchUserDetails,
  setUserData,
  actionType,
}) => {
  const initialUserFilter = {
    skip: 0,
    limit: 10,
    userId: userRecord?.id,
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const handleCancel = () => {
    if (setUserData) {
      setUserData();
    }
    setShowModal(false);
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userListData, setUserListData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(true);
  const [approverData, setApproverData] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [requiredStageIds, setRequiredStageIds] = useState([]);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectionType, setSelectionType] = useState({});
  const [activeRow, setActiveRow] = useState();
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [drawerUserId, setDrawerUserId] = useState();
  const [selectedUserData, setSelectedUserData] = useState();
  const [isTouch, setIsTouch] = useState(false);
  // eslint-disable-next-line no-console
  console.log('selectionType', selectionType);
  const [fetchUserData, { loading }] = useLazyQuery(USER_PROJECT_LIST, {
    variables: { filter: userFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.userProjectList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.userProjectList?.total,
      };
      setRequiredStageIds(res?.userProjectList?.requiredProjectIds);
      if (scrollFlag) {
        const datalist = [...userListData, ...data];
        setUserListData(datalist);
        setScrollFlag(false);
        if (datalist?.length === res?.userProjectList?.total) {
          setHasMore(false);
        }
      } else {
        const datalist = [...userListData, ...data];
        setUserListData(datalist);
      }
      setPaginationProp(pagination);
    },
    onError() {},
  });

  // eslint-disable-next-line no-unused-vars
  const [deactivateUserApprover, { loading: deactivateUserLoading }] =
    useMutation(DEACTIVATE_USER_APPROVER, {
      onError() {},
      onCompleted() {
        setShowModal(false);
        refetchUserDetails();
        if (setUserData) {
          setUserData();
        }
      },
    });

  const [replicateUser, { loading: replicateUserLoading }] = useMutation(
    REPLICATE_USER,
    {
      onError() {},
      onCompleted() {
        setShowModal(false);
        refetchUserDetails();
        if (setUserData) {
          setUserData();
        }
      },
    },
  );

  useEffect(() => {
    fetchUserData({ variables: { filter: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
          },
        },
      });
    }
  };

  const getActionButtons = (record) => {
    const isRequired = includes(requiredStageIds, record?.projectId);
    const handleReplace = (e) => {
      e?.stopPropagation?.();
      setApproverData(record);
      handleAddEditUser();
      setShowReplaceModal(true);
    };
    return isDesktopViewport ? (
      <div
        className={clsx(
          'd-flex justify-between align-center readonly-select',
          isRequired && !record?.newReplacedUser && 'readonly-select-with-glow',
          record?.newReplacedUser && 'readonly-selected-with-glow',
        )}
      >
        <div className="ellipsis-text">
          {record?.newReplacedUser?.name || 'Select User'}
        </div>
        <DownOutlined />
      </div>
    ) : (
      <Button
        type="text"
        className={clsx(
          'fw-semi-bold ',
          isRequired && 'text-primary',
          record?.newReplacedUser && 'replaced-button',
        )}
        onClick={handleReplace}
      >
        {titleCase(actionType)}
      </Button>
    );
  };

  const getUserActivityCount = (record) => {
    return (
      <div className={clsx('user-activity-count', 'd-flex')}>
        <div className="title">
          Checklists :{' '}
          <span className="count">{record?.assignedChecklistCount || 0}</span>
        </div>
        <div className={clsx('title', 'ml-5')}>
          RFI: <span className="count">{record?.assignedRfiCount || 0}</span>
        </div>
        <div className={clsx('title', 'ml-5')}>
          Approval stage:{' '}
          <span className="count">
            {record?.approvalPendingStageCount || 0}
          </span>
        </div>
      </div>
    );
  };

  const defaultColumns = [
    {
      title: '#',
      key: 'id',
      width: '60px',
      render: (text, record, index) => {
        return <div>{userFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'PROJECT',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (_, record) => {
        return <EllipsisText text={record?.project?.name} />;
      },
    },
    {
      title: 'ROLE',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      render: (_, record) => {
        return <EllipsisText text={PROJECT_ROLES_LABEL[record?.roles]} />;
      },
    },
    {
      title: 'ASSIGNMENT',
      dataIndex: 'approvers',
      key: 'approver',
      width: '300px',
      render: (_, record) => getUserActivityCount(record),
    },
    {
      dataIndex: 'replacedUser',
      align: 'right',
      editable: true,
      width: '200px',
      render: (action, record) => getActionButtons(record),
    },
  ];

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });
  const handleRefetch = () => {
    fetchUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: userListData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  const handleDeactivate = async () => {
    if (actionType === USER_LIST_ACTIONS.DEACTIVATE) {
      // await deactivateUserApprover({
      //   variables: {
      //     id: userRecord?.id,
      //     data: map(replacedApproverData, (record) => omit(record, ['id'])),
      //   },
      // });
    } else if (actionType === USER_LIST_ACTIONS.REPLICATE) {
      // eslint-disable-next-line no-console
      console.log('outeeeee    userListData', userListData, selectionType);
      replicateUser({
        variables: {
          data: {
            id: userRecord?.id,
            replicateUsers:
              selectionType?.type === 'all'
                ? undefined
                : map(userListData, (record) => ({
                    projectId: record?.projectId,
                    userId: record?.newReplacedUser?.id,
                  })),
            allProjects: selectionType?.type === 'all',
            userId:
              selectionType?.type === 'all'
                ? userListData?.[0]?.newReplacedUser?.id
                : undefined,
          },
        },
      });
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRowsData, info) => {
      setSelectedRows(selectedRowsData);
      setSelectionType(
        !selectedRowsData?.length > 0 ? { type: 'single' } : info,
      );
      setDrawerUserId();
      setTimeout(() => {
        setIsTouch(!!selectedRowsData?.length);
      }, 200);
    },
    selectedRowKeys: selectedRows?.map((data) => data?.id),
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 10;
    if (scrolledToBottom && hasMore && !loading) {
      const obj = {
        variables: {
          filter: {
            ...userFilter,
            skip: userListData?.length,
            limit: 10,
          },
        },
      };
      fetchUserData(obj);
    }
  };
  const EditableContext = createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [editableForm] = Form.useForm();
    return (
      <Form form={editableForm} component={false}>
        <EditableContext.Provider value={editableForm}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const [isOpen, setIsOpen] = useState(false);

  const EditableCell = ({
    editable,
    children,
    dataIndex,
    record,
    title,
    ...restProps
  }) => {
    const editableForm = useContext(EditableContext);
    const inputRef = useRef(null);

    useEffect(() => {
      if (isOpen) {
        inputRef?.current?.focus();
        editableForm.setFieldsValue({
          [dataIndex]: record?.newReplacedUser?.id,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const save = async (_, selectedUserRecord) => {
      setSelectedUserData(selectedUserRecord);
      try {
        const values = await editableForm.validateFields();
        const index = findIndex(userListData, { id: activeRow?.id });
        if (values?.replacedUser && selectedUserRecord) {
          const updatedUser = {
            ...userListData[index],
            newReplacedUser: selectedUserRecord?.[0],
          };
          userListData[index] = updatedUser;
        }
        setActiveRow();
        setIsOpen(false);
      } catch (errInfo) {
        // eslint-disable-next-line no-console
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode =
        activeRow?.id === record?.id &&
        (selectedRows?.length > 0
          ? !includes(
              map(selectedRows, (item) => item?.id),
              record?.id,
            )
          : true) ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <CommonDropdown
              inputRef={inputRef}
              placeholder="Select User"
              showSearch
              optionFilterProp="children"
              open={isOpen && activeRow?.id === record?.id}
              onBlur={save}
              onClear={save}
              fetchPolicy="network-only"
              query={GET_USERS_DROPDOWN_LIST}
              variables={{
                filter: {
                  replicateUserId: Number(userRecord?.id),
                },
              }}
              responsePath="userDropdownList.data"
              valuePath="id"
              labelPath="name"
              optionKey="user"
              customOptions={selectedUserData}
              getPopupContainer={() =>
                // eslint-disable-next-line no-undef
                document.querySelector('.replace-table-wrapper')
              }
              onChange={(id, selectedUserRecord) => {
                save(id, selectedUserRecord);
              }}
            />
          </Form.Item>
        ) : (
          <div
            className={clsx(
              'editable-cell-value-wrap',
              selectedRows?.length > 0 &&
                includes(
                  map(selectedRows, (item) => item?.id),
                  record?.id,
                ) &&
                'cursor-not-allowed',
            )}
            onClick={() => {
              setIsOpen(true);
              setActiveRow(record);
            }}
          >
            {children}
          </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const onChange = async (id, selectedUserRecord) => {
    setDrawerUserId(id);
    try {
      map(selectedRows, (record) => {
        const index = findIndex(userListData, { id: record?.id });
        if (selectedUserRecord) {
          const updatedUser = {
            ...userListData[index],
            newReplacedUser: selectedUserRecord?.[0],
          };
          userListData[index] = updatedUser;
          setUserListData([...userListData]);
        }
      });
      setActiveRow();
      setIsOpen(false);
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Save failed:', errInfo);
    }
  };
  return (
    <div id="deactive-user-modal">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        onCancel={() => handleCancel()}
        footer={null}
        closable={false}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('deactive-user-modal')
        }
      >
        <div className="title">
          Checklist where <b>{userRecord?.name}</b> is Approver
        </div>
        {isDesktopViewport ? (
          <div className="replace-table-wrapper">
            <CommonTable
              columns={columns}
              components={components}
              data={userListData || []}
              onChange={handleTableChange}
              rowKey={(obj) => obj?.id}
              rowSelection={{
                ...rowSelection,
              }}
              onScroll={handleScroll}
              scroll={{
                y: 500,
              }}
            />
          </div>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={userListData?.length}
            skeletonRows={columns?.length - 2}
          >
            {map(userListData, (user, index) => {
              return (
                <CommonCard key={user?.id}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-header fw-medium">
                        <span>
                          <EllipsisText text={user?.project?.name} />
                        </span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Role:</span>
                          <EllipsisText
                            className="text-secondary"
                            text={PROJECT_ROLES_LABEL[user?.roles]}
                          />
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Assignment:</span>
                          {getUserActivityCount(user)}
                        </div>
                      </div>
                    </div>
                    <span className="d-flex position-absolute user-action-btn">
                      {getActionButtons(user)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
        <div className="mt-20 d-flex justify-between align-center">
          <div>
            <p className="mt-10 fw-medium d-flex align-center">
              Note:
              <Avatar
                className={clsx('avatar-color', 'background-primary')}
                size={14}
              />
              Mandatory to replace.
            </p>
          </div>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Popconfirm
              title="Are you sure you want to Deactivate User?"
              onConfirm={handleDeactivate}
              okText="Yes"
              cancelText="No"
              disabled={
                !requiredStageIds?.every((r) =>
                  includes(
                    map(
                      filter(userListData, (data) => data?.newReplacedUser),
                      (value) => value?.projectId,
                    ),
                    r,
                  ),
                )
              }
            >
              <Button
                shape="round"
                type="primary"
                className="save-button ml-5"
                htmlType="submit"
                loading={deactivateUserLoading || replicateUserLoading}
                disabled={
                  !requiredStageIds?.every((r) =>
                    includes(
                      map(
                        filter(userListData, (data) => data?.newReplacedUser),
                        (value) => value?.projectId,
                      ),
                      r,
                    ),
                  )
                }
              >
                Deactivate (
                {filter(userListData, (data) => data?.newReplacedUser)?.length})
              </Button>
            </Popconfirm>
          </div>
        </div>
        {isTouch && (
          <div className="d-flex align-center justify-center">
            {isDesktopViewport && (
              <div
                className={clsx(
                  'action-drawer',
                  selectedRows?.length > 0
                    ? 'fade-in-from-bottom visible'
                    : 'fade-out-to-bottom',
                )}
                onAnimationEnd={(e) => {
                  if (!selectedRows?.length) {
                    e.currentTarget.classList.remove('visible');
                  }
                }}
              >
                <div className="d-flex align-center">
                  <Button
                    shape="round"
                    type="primary"
                    className="mr-10"
                    iconPosition="end"
                    icon={<CloseCircleFilled />}
                    onClick={() => {
                      setTimeout(() => {
                        setIsTouch(false);
                      }, 200);
                      setSelectedRows([]);
                      setSelectionType({});
                    }}
                  >
                    {`${selectedRows?.length} ${
                      selectedRows?.length > 1 ? 'Users' : 'User'
                    } Selected`}
                  </Button>
                  <CommonDropdown
                    getPopupContainer={() =>
                      // eslint-disable-next-line no-undef
                      document.querySelector('#deactive-user-modal')
                    }
                    placeholder="Select User"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    query={GET_USERS_DROPDOWN_LIST}
                    variables={{
                      filter: {
                        replicateUserId: Number(userRecord?.id),
                      },
                    }}
                    responsePath="userDropdownList.data"
                    valuePath="id"
                    labelPath="name"
                    optionKey="user"
                    fetchPolicy="network-only"
                    value={drawerUserId}
                    onChange={(id, selectedUserRecord) => {
                      onChange(id, selectedUserRecord);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
      {showReplaceModal && (
        <ReplaceUserModal
          setShowModal={setShowReplaceModal}
          showModal={showReplaceModal}
          approverData={approverData}
          userListData={userListData}
          setUserListData={setUserListData}
        />
      )}
    </div>
  );
};

export default ReplaceReplicateModal;
