import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Alert, Card } from 'antd';
import { filter, includes, isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import HistoryRouter from './common/HistoryRouter';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ROUTES,
  SYSTEM_ROLES,
  TAB_KEYS,
} from './common/constants';
import { initGA } from './common/trackEvents';
import { useOnlineStatus } from './common/useNetworkDetect';
import CanPerform from './components/CanPerform';
import HasAccess from './components/HasAccess';
import LoaderComponent from './components/LoaderComponent';
import history from './historyData';
import ApproverLink from './modules/approver/ApproverLink';
import ApproverSuccess from './modules/approver/ApproverSuccess';
import ChangePassword from './modules/auth/ChangePassword';
import ForgotPassword from './modules/auth/ForgotPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import Maintenance from './modules/auth/Maintenance';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import SetPassword from './modules/auth/SetPassword';
import Dashboard from './modules/dashboard/Dashboard';
import SetUpEqcDetails from './modules/eqctype/components/eqcDetails/EqcDetails';
import Logs from './modules/logs/Logs';
import RedirectToTab from './modules/logs/components/RedirectToTab';
import Profile from './modules/profile/Profile';
import AddProject from './modules/projects/AddProject';
import Projects from './modules/projects/Projects';
import EditProjectDetails from './modules/projects/components/EditProjectDetails';
import RedirectToAddProject from './modules/projects/components/RedirectToAddProject';
import RedirectToProjectDetails from './modules/projects/components/RedirectToProjectDetails';
import ProjectDetails from './modules/projects/components/projectDetails/ProjectDetails';
import UnassignAgency from './modules/projects/components/projectDetails/pages/agencies/UnassignAgency';
import EqcDetails from './modules/projects/components/projectDetails/pages/eqc/eqcDetails/EqcDetails';
import EqcTypeDetails from './modules/projects/components/projectDetails/pages/eqcTypes/eqcTypeDetails/EqcTypeDetails';
import InstructionDetails from './modules/projects/components/projectDetails/pages/instructions/instructionDetails';
import RfiDetails from './modules/projects/components/projectDetails/pages/rfi/RfiDetails';
import Setup from './modules/projects/components/projectDetails/pages/setup/Setup';
import Reports from './modules/report/Reports';
import ReportAssetsViewer from './modules/reportAssetsViewer/ReportAssetsViewer';
import Todo from './modules/todo/Todo';
import Details from './modules/todo/issues/details';
import ResponseSuccess from './modules/todo/issues/details/ResponseSuccess';
import { GET_PROFILE } from './modules/users/graphql/Queries';

const MyFallbackComponent = ({ error, componentStack }) => (
  <div>
    <p>
      <strong>Oops! An error occured!</strong>
    </p>
    <p>Below is the details…</p>
    <p>
      <strong>Error:</strong> {error.toString()}
    </p>
    <p>
      <strong>Stacktrace:</strong> {componentStack}
    </p>
  </div>
);

const RedirectToEqcType = ({ children }) => {
  const {
    state: {
      currentUser,
      currentUser: { roles },
    },
  } = useContext(AppContext);
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  if (isEmpty(currentUser)) {
    return <LoaderComponent />;
  }
  if (includes(roles, SYSTEM_ROLES.CHECKLIST_MAKER)) {
    return <Navigate to={`${ROUTES.SETUP}${ROUTES.CHECKLISTS}`} />;
  }
  if (!hasInspectionAccess) {
    return <Navigate to={ROUTES.PROJECTS} />;
  }
  return children;
};

const RoutesCollection = () => {
  const {
    state: {
      currentUser: { projectUsers },
    },
  } = useContext(AppContext);
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const hasInstructionAccess = !!HasAccess({ type: ACCESS_TYPE.INSTRUCTION });
  const isProjectAdmin = !!filter(
    projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN,
  ).length;
  const isInspector = !!filter(
    projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.INSPECTOR,
  ).length;

  const AUTH_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.FORGOT, element: <ForgotPassword /> },
        { path: ROUTES?.RESET, element: <ResetPassword /> },
        { path: ROUTES?.LOGIN, element: <Login /> },
      ],
    },
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        { path: ROUTES?.LOGOUT, element: <Logout /> },
        { path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> },
      ],
    },
    {
      path: ROUTES.MAIN,
      element: <Outlet />,
      children: [
        { path: ROUTES?.SET_PASSWORD, element: <SetPassword /> },
        { path: ROUTES?.REPORT_IMAGE_VIEWER, element: <ReportAssetsViewer /> },
      ],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.MAIN,
              element: (
                <RedirectToEqcType>
                  <Dashboard />
                </RedirectToEqcType>
              ),
            },
          ],
        },
      ],
    },
  ];

  const PROJECT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES.PROJECTS,
              element: <Projects />,
            },
            {
              path: ROUTES.ADD_PROJECTS,
              element: <AddProject />,
            },
            {
              path: `${ROUTES.PROJECTS}/:projectId/edit`,
              element: <EditProjectDetails />,
            },
            ...(CanPerform({ action: ALLOWED_ACTION_KEYS.ADD_PROJECT })
              ? [
                  {
                    path: `${ROUTES.ADD_PROJECTS}/:projectId/:step`,
                    element: <AddProject />,
                  },
                ]
              : []),
            {
              path: `${ROUTES.ADD_PROJECTS}/:projectId`,
              element: <RedirectToAddProject />,
            },
            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.EQC}/:eqcId`,
                    element: <EqcDetails />,
                  },
                ]
              : []),
            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.CHECKLISTS}/:eqcTypeId`,
                    element: <EqcTypeDetails />,
                  },
                ]
              : []),
            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.CHECKLISTS}/:eqcTypeId/draft`,
                    element: <EqcTypeDetails />,
                  },
                ]
              : []),
            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.CHECKLISTS}/:eqcTypeId/changelog`,
                    element: <EqcTypeDetails />,
                  },
                ]
              : []),
            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.RFI}/:rfiId`,
                    element: <RfiDetails />,
                  },
                ]
              : []),

            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.RFI}/:rfiId/draft`,
                    element: <RfiDetails />,
                  },
                ]
              : []),

            ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.RFI}/:rfiId/changelog`,
                    element: <RfiDetails />,
                  },
                ]
              : []),
            {
              path: `${ROUTES.PROJECTS}/:projectId`,
              element: <RedirectToProjectDetails />,
            },
            ...(HasAccess({ type: ACCESS_TYPE.INSTRUCTION })
              ? [
                  {
                    path: `${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.INSTRUCTION}/:instructionId`,
                    element: <InstructionDetails />,
                  },
                ]
              : []),

            {
              path: `${ROUTES.PROJECTS}/:projectId/:tab/unassigned`,
              element: <ProjectDetails />,
            },
            {
              path: `${ROUTES.PROJECTS}/:projectId/:tab`,
              element: <ProjectDetails />,
            },
          ],
        },
      ],
    },
  ];

  const LOGS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: `${ROUTES.LOGS}/:tab`, element: <Logs /> },
            {
              path: ROUTES.LOGS,
              element: <RedirectToTab />,
            },
          ],
        },
      ],
    },
  ];

  const TODO_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: `${ROUTES.TODO}/:tab`, element: <Todo /> },
            { path: `${ROUTES.TODO}/:tab/:id`, element: <Details /> },
            {
              path: `${ROUTES.TODO}/:tab/:id/success`,
              element: <ResponseSuccess />,
            },
          ],
        },
      ],
    },
  ];

  const REPORT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [{ path: ROUTES.REPORTS, element: <Reports /> }],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES.PROFILE, element: <Profile /> },
            { path: ROUTES?.CHANGE, element: <ChangePassword /> },
          ],
        },
      ],
    },
  ];

  const APPROVER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES.APPROVER_LINK, element: <ApproverLink /> },
            { path: ROUTES.APPROVER_SUCCESS, element: <ApproverSuccess /> },
          ],
        },
      ],
    },
  ];

  const SETUP_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: `${ROUTES.SETUP}/:tab`, element: <Setup /> },
            ...(hasInspectionAccess &&
            CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_CHECKLISTS_PAGE })
              ? [
                  {
                    path: `${ROUTES.SETUP}${ROUTES.CHECKLISTS}/:eqcTypeId`,
                    element: <SetUpEqcDetails />,
                  },
                ]
              : []),
            ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_AGENCY_PAGE })
              ? [
                  {
                    path: `${ROUTES.SETUP}${ROUTES.UNASSIGN_AGENCIES}`,
                    element: (
                      <Card>
                        <UnassignAgency isGlobal />
                      </Card>
                    ),
                  },
                ]
              : []),
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const routes = [
    ...DASHBOARD_MODULES,
    ...AUTH_MODULES,
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_PROJECTS_PAGE })
      ? PROJECT_MODULES
      : []),
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_LOGS_PAGE })
      ? LOGS_MODULES
      : []),
    ...(hasInspectionAccess &&
    (CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_TODO_PAGE }) ||
      isProjectAdmin ||
      isInspector)
      ? TODO_MODULES
      : []),
    ...((hasInspectionAccess || hasInstructionAccess) &&
    (CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_REPORTS_PAGE }) ||
      isProjectAdmin)
      ? REPORT_MODULES
      : []),
    ...USER_MODULES,
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_SETUP_PAGE })
      ? SETUP_MODULES
      : []),
    ...APPROVER_MODULES,
    ...OTHER_MODULES,
  ];
  const element = useRoutes(routes);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const idToken = getToken();
  const isOnline = useOnlineStatus();
  const {
    state: { currentRole, projectRole, currentUser },
  } = useContext(AppContext);

  const { loading } = useQuery(GET_PROFILE, {
    // eslint-disable-next-line no-undef
    skip: !idToken || window?.location?.pathname === ROUTES.LOGOUT,
    onCompleted: (res) => {
      initializeAuth('', '', res?.getLoggedInUser);
    },
    onError() {},
  });

  useEffect(() => {
    initGA(process.env.REACT_APP_GA_CONFIG);
    // eslint-disable-next-line no-undef
  }, []);
  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  if (
    // eslint-disable-next-line no-undef
    window?.location?.pathname !== ROUTES.LOGOUT &&
    (loading || (!isEmpty(currentUser) && !(currentRole || projectRole)))
  )
    return <LoaderComponent />;

  return (
    <>
      {!isOnline && (
        <div className="alert-div">
          <Alert
            message="Please check your network connection and try again."
            type="error"
            banner
          />
        </div>
      )}
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        {maintenance === 'true' ? (
          <Maintenance />
        ) : (
          <HistoryRouter history={history}>
            <RoutesCollection />
          </HistoryRouter>
        )}
      </Sentry.ErrorBoundary>
    </>
  );
};
export default RoutesWrapper;
